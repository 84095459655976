import {Component} from "react";

export default function InputField({label, id, type, name, placeholder, value, onChange, className, style}) {
    if (style === 'dark') {
        className += ' bg-gray-900 text-white border-gray-800';
    }

    if (className) {
        className += ' ' + className;
    }

    return (
        <>
            {label && (
                <label className={'block mb-1'}>{label}</label>
            )}
            <input
                id={id}
                type={type || 'text'}
                name={name}
                className={'w-full border border-gray-300 rounded-md p-3 mb-3' + className}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </>
    )
}