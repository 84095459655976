import {Container} from "../components/utilities/Container";
import InputField from "../components/utilities/InputField";
import ErrorAlert from "../components/utilities/ErrorAlert";
import {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import Button from "../components/utilities/Button";
import {ApiClient} from "../ApiClient";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);

    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const expires = localStorage.getItem('tokenExpires');

    if (token && new Date(expires) > new Date()) {
        window.location.href = '/dashboard';
    }

    return (
        <>
            <img src={'/logo.svg'} alt={'BF-Oks'} className={'mx-auto max-w-[130px] mb-10'}/>
            
            <div
                className={`absolute left-0 top-0 w-full h-full -z-10`}>
                <div className={'absolute w-full h-full bg-slate-200 bg-blend-overlay'}></div>
            </div>
            <Container>
                <h1 className={'text-center text-3xl font-bold mb-5'}>Chauffør App</h1>
                <form onSubmit={async (e) => {
                    e.preventDefault();

                    const response = await ApiClient.post('/api/auth', {
                        phoneNumber: username,
                        password: password
                    });

                    const json = await response.json();

                    if (response.status === 200) {
                        localStorage.setItem('token', json.token);
                        localStorage.setItem('tokenExpires', json.expires);
                        localStorage.setItem('userId', json.userId);
                        localStorage.setItem('role', json.role);

                        navigate('/dashboard')
                    } else {
                        if (json.errors) {
                            setErrors(json.errors)
                        }
                    }
                }}>
                    <ErrorAlert errors={errors}></ErrorAlert>
                    <div className="mb-3">
                        <InputField
                            placeholder={'Indtast dit telefonnummer'}
                            name={'username'}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}/>
                    </div>
                    <div className="mb-3">
                        <InputField
                            placeholder={'Indtast dit password'}
                            name={'password'}
                            type={'password'}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}/>
                    </div>
                    <div className={'text-center'}>
                        <Button type={'brand'} className={'w-full'}>Login</Button>
                    </div>
                </form>
            </Container>
        </>
    )
}
