export default function SelectField({name, label, placeholder, value, onChange, className, options}) {
    return (
        <>
            {label && (
                <label className={'block mb-1'}>{label}</label>
            )}
            <select
                name={name}
                className={'w-full border border-gray-300 rounded-md p-3 ' + className}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </>
    )
}