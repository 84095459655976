import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import './custom.css';
import BoxReportService from "./services/BoxReportService";

export default function App() {
    const worker = new Worker('./worker.js');
    let processingBoxReports = false;
    worker.onmessage = (e) => {
        if (e.data === 'processBoxReports') {
            if (processingBoxReports) {
                return;
            }

            try {
                BoxReportService.processBoxReports();
            } catch (e) {
                console.error(e);
            } finally {
                processingBoxReports = false;
            }
        }
    }

    return (
        <Layout>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const {element, ...rest} = route;
                    return <Route key={index} {...rest} element={element}/>;
                })}
            </Routes>
        </Layout>
    );
}
