import {ApiClient} from "../ApiClient";

export default class BoxReportService {
    static createBoxReport(barcode, deliveryBoxCount, deliveryPalletCount, returnBoxCount, returnPalletCount) {
        let boxReports = localStorage.getItem('boxReports');
        if (!boxReports) {
            boxReports = [];
        } else {
            boxReports = JSON.parse(boxReports);
        }
        boxReports.push({
            barcode: barcode,
            deliveryBoxCount: deliveryBoxCount,
            deliveryPalletCount: deliveryPalletCount,
            returnBoxCount: returnBoxCount,
            returnPalletCount: returnPalletCount,
            timestamp: new Date(),
            userId: localStorage.getItem('userId')
        });
        localStorage.setItem('boxReports', JSON.stringify(boxReports));
        
        this.processBoxReports();
    }

    static getBoxReports() {
        let boxReports = localStorage.getItem('boxReports');
        if (!boxReports) {
            boxReports = [];
        } else {
            boxReports = JSON.parse(boxReports);
        }
        return boxReports;
    }

    static deleteBoxReport(boxReport) {
        let boxReports = localStorage.getItem('boxReports');
        if (!boxReports) {
            boxReports = [];
        } else {
            boxReports = JSON.parse(boxReports);
        }
        boxReports = boxReports.filter((report) => {
            return report.barcode !== boxReport.barcode;
        });
        localStorage.setItem('boxReports', JSON.stringify(boxReports));
    }

    // A flag to prevent multiple calls to this function
    static processingBoxReports = false;

    static processBoxReports() {
        if (this.processingBoxReports) {
            return;
        }

        this.processingBoxReports = true;

        try {
            let boxReports = localStorage.getItem('boxReports');
            if (!boxReports) {
                boxReports = [];
            } else {
                boxReports = JSON.parse(boxReports);
            }

            boxReports.forEach((boxReport) => {
                // If the box report is older than 7 days, delete it
                if (new Date(boxReport.timestamp).getTime() < new Date().getTime() - 7 * 24 * 60 * 60 * 1000) {
                    this.deleteBoxReport(boxReport);
                    return;
                }

                ApiClient.post('/api/box-reports', boxReport).then((response) => {
                    if (response.status === 200) {
                        this.deleteBoxReport(boxReport);
                    }
                })
            })
        } finally {
            this.processingBoxReports = false;
        }
    }
}