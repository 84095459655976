import {Container} from "../../components/utilities/Container";
import BackButton from "../../components/BackButton";
import {useEffect, useState} from "react";
import {ApiClient} from "../../ApiClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRemove, faUser} from "@fortawesome/pro-solid-svg-icons";
import InputField from "../../components/utilities/InputField";
import Button from "../../components/utilities/Button";
import ErrorAlert from "../../components/utilities/ErrorAlert";
import SelectField from "../../components/utilities/SelectField";
import CustomAlert from "../../components/CustomAlert";

export default function Users() {
    const [users, setUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);

    const fetchUsers = async () => {
        ApiClient.get('/api/users').then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    setUsers(json);
                })
            }
        })
    }

    useEffect(() => {

        fetchUsers().catch(error => {
            console.error(error);
        });

    }, [])

    return (
        <>
            {showEditor && (
                <UserEditor user={editUser} onCancel={() => {
                    setShowEditor(false);
                    setEditUser(null);
                }} onSuccess={() => {
                    setShowEditor(false);
                    setEditUser(null);
                    fetchUsers().catch(error => {
                        console.error(error);
                    })
                }}/>
            )}
            {deleteUser && (
                <CustomAlert errorMessage={'Er du sikker på at du vil slette denne bruger?'} onOk={() => {
                    ApiClient.delete(`/api/users/${deleteUser.id}`).then((response) => {                        
                        if (response.status === 200) {
                            setDeleteUser(null);
                            fetchUsers().catch(error => {
                                    console.error(error);
                                }
                            )
                        }
                    })
                }} onCancel={() => {
                    setDeleteUser(null);
                }}/>
            )}
            <Container>
                <BackButton to={'/dashboard'}/>
                <h1 className={'text-xl font-bold text-center mb-3'}>Brugere</h1>
                <div className="text-center mb-3">
                    <Button onClick={() => {
                        setShowEditor(true);
                        setEditUser(null);
                    }}>Opret bruger</Button>
                </div>
                {users.map((user, index) => {
                    return (
                        <div key={index}>
                            {userCard(user, () => {
                                setShowEditor(true);
                                setEditUser(user);
                            }, () => {
                                setDeleteUser(user);
                            })}
                        </div>
                    )
                })}
            </Container>
        </>
    )
}

const userCard = (user, onClick, onDelete) => {
    return (
        <div className={'bg-white rounded p-3 shadow cursor-pointer mb-3 cursor-pointer relative'} onClick={onClick}>
            <FontAwesomeIcon icon={faRemove} className={'top-3 right-3 text-red-500 text-xl absolute'}
                             onClick={(e) => {
                                 e.preventDefault();
                                 e.stopPropagation();

                                 onDelete();
                             }}/>
            <div className={'flex items-center justify-center'}>
                <FontAwesomeIcon icon={faUser} className={'text-4xl text-gray-500'}/>
            </div>
            <div className={'mt-2'}>
                <h3 className={'text-xl font-bold'}>{user.firstName} {user.lastName}</h3>
                <p className={'text-gray-500'}>{user.phoneNumber}<br/>{user.role}</p>
            </div>
        </div>
    )
}

function UserEditor({user, onSuccess, onCancel}) {
    let edit = user !== null;
    let submitUrl = edit ? `/api/users/${user.id}` : '/api/users';

    if (!edit) {
        user = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            password: '',
            role: 'User'
        }
    }

    const [formState, setFormState] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        password: user.password,
        role: user.role,
    });

    const [errors, setErrors] = useState([]);

    const onSubmit = (e) => {
        e.preventDefault();

        let method = edit ? 'PUT' : 'POST';

        console.log(JSON.stringify(formState), 'form state');

        ApiClient.fetch(submitUrl, {
            method: method,
            body: JSON.stringify(formState)
        }).then((response) => {
            if (response.status === 200) {
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                response.json().then(json => {
                    if (json.errors) {
                        setErrors(json.errors)
                    }
                })
            }
        })
    }

    return (
        <>
            <div className={'fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-50'} onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onCancel();
                }
            }}>
                <Container>
                    <div className={'bg-white rounded p-3 mt-5 shadow'}>
                        <ErrorAlert errors={errors}/>
                        <form onSubmit={onSubmit}>
                            <InputField label={'Fornavn'} name={'firstName'} value={formState.firstName}
                                        className={'mb-3'}
                                        onChange={(e) => {
                                            setFormState({
                                                ...formState,
                                                firstName: e.target.value
                                            })
                                        }}/>
                            <InputField label={'Efternavn'} name={'lastName'} value={formState.lastName}
                                        className={'mb-3'}
                                        onChange={(e) => {
                                            setFormState({
                                                ...formState,
                                                lastName: e.target.value
                                            })
                                        }}/>
                            <InputField label={'Telefonnummer'} name={'phoneNumber'} value={formState.phoneNumber} className={'mb-3'}
                                        onChange={(e) => {
                                            setFormState({
                                                ...formState,
                                                phoneNumber: e.target.value
                                            })
                                        }}/>
                            <InputField label={'Password'} type={'password'} name={'password'}
                                        value={formState.password}
                                        className={'mb-3'}
                                        onChange={(e) => {
                                            setFormState({
                                                ...formState,
                                                password: e.target.value
                                            })
                                        }}/>
                            <SelectField label={'Rolle'} name={'role'} value={formState.role} className={'mb-3'}
                                         options={[
                                             {label: 'Admin', value: 'Admin'},
                                             {label: 'User', value: 'User'}
                                         ]}
                                         onChange={(e) => {
                                             setFormState({
                                                 ...formState,
                                                 role: e.target.value
                                             })
                                         }}/>
                            <Button type={'submit'}>Gem</Button>
                        </form>
                    </div>
                </Container>
            </div>
        </>
    )
}