import React, {Component} from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <div className={'bg-slate-200 p-3'}></div>
                {this.props.children}
            </div>
        );
    }
}
