import {Container} from "../components/utilities/Container";
import ShortcutCard from "../components/ShortcutCard";
import {faBox, faList, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate} from "react-router-dom";

export default function Dashboard() {
    const navigate = useNavigate();
    
    let shortcuts = [
        {
            icon: faBox,
            title: 'Kasseindberetning',
            description: 'Indberet kasser ved levering hos kunde',
            onClick: () => {
                navigate('/box-report')
            }
        },
        {
            icon: faList,
            title: 'Kasser til overførsel',
            description: 'Se kasser der endnu ikke er overført',
            onClick: () => {
                navigate('/pending-box-reports')
            }
        }
    ]
    
    const role = localStorage.getItem('role');
    if (role === "Admin") {
        shortcuts.push({
            icon: faUsers,
            title: 'Brugere',
            description: 'Administrer brugere for applikationen',
            onClick: () => {
                navigate('/admin/users');
            }
        })
    }
    
    return (
        <>
            <Container>
                {shortcuts.map((shortcut, index) => {
                    return (
                        <ShortcutCard key={index} icon={shortcut.icon} title={shortcut.title} description={shortcut.description} onClick={shortcut.onClick}/>
                    )
                })}
            </Container>
        </>
    )
}