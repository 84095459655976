import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";

export default function BackButton({to, requireConfirmation}) {
    const navigate = useNavigate();

    return (
        <div className={'flex items-center justify-center -mt-5 mb-2'}>
            <div className={'inline-block cursor-pointer'} onClick={(e) => {
                e.preventDefault();

                if (requireConfirmation) {
                    if (window.confirm('Er du sikker på du vil gå tilbage? Din nuværende indtastning gemmes ikke.')) {
                        navigate(to);
                    }
                } else {
                    navigate(to)
                }
            }}>
                <FontAwesomeIcon icon={faArrowLeft} className={'text-1xl mr-1 text-gray-500'}/>
                Tilbage
            </div>
        </div>
    )
}