import {Container} from "../components/utilities/Container";
import BackButton from "../components/BackButton";
import {useEffect, useState} from "react";
import Button from "../components/utilities/Button";
import BoxReportService from "../services/BoxReportService";

export default function PendingBoxReports() {
    const [boxReports, setBoxReports] = useState([]);
    
    useEffect(() => {
        let boxReportsJson = localStorage.getItem('boxReports');
        if (!boxReportsJson) {
            boxReportsJson = [];
        } else {
            boxReportsJson = JSON.parse(boxReportsJson);
        }
        
        setBoxReports(boxReportsJson);
    }, [])
    
    return (
        <>
            <Container>
                <BackButton to={'/dashboard'}/>
                <h1 className={'text-xl font-bold text-center mb-3'}>Kasser til overførsel</h1>
                {boxReports.length <= 0 && (
                    <p className={'text-center'}>Ingen kasser afventer overførsel...</p>
                )}
                {boxReports.length > 0 && (
                    <>
                        {boxReports.map((boxReport, index) => {
                            return (
                                <div key={index} className={'bg-white rounded p-3 mb-3'}>
                                    <div>Stregkode: {boxReport.barcode}</div>
                                </div>
                            )
                        })}
                        <Button onClick={() => {
                            try {
                                BoxReportService.processBoxReports();
                            } catch {
                                alert('Der skete en fejl under overførsel af kasser...');
                            }
                        }}>Overfør kasser</Button>
                    </>
                )}
            </Container>
        </>
    )
}