import {Container} from "../components/utilities/Container";
import BackButton from "../components/BackButton";
import {QrScanner} from "@yudiel/react-qr-scanner";
import {useEffect, useState} from "react";
import InputField from "../components/utilities/InputField";
import Button from "../components/utilities/Button";
import BoxReportService from "../services/BoxReportService";

export default function BoxReport() {
    const [barcode, setBarcode] = useState('');
    const [currentStep, setCurrentStep] = useState(1);

    const [counts, setCounts] = useState({
        deliveryBoxCount: 0,
        deliveryPalletCount: 0,
        returnBoxCount: 0,
        returnPalletCount: 0
    });

    useEffect(() => {
        if (window.onbeforeunload) {
            window.removeEventListener('beforeunload', window.onbeforeunload);
        }

        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
    }, [])

    return (
        <>
            <Container>
                <BackButton requireConfirmation={true} to={'/dashboard'}/>
                {currentStep === 1 && (
                    <>
                        <div className="text-center mb-3">
                            <h1 className={'text-xl font-bold'}>Kasseindberetning</h1>
                            <p className={'text-gray-500'}>Scan eller indtast stregkode fra følgeseddel</p>
                        </div>
                        <QrScanner onError={(error) => {
                            console.error(error);
                        }} onResult={(result) => {

                            if (result) {
                                setBarcode(result.text);
                                document.getElementById('barcodeField').value = result.text;
                            }

                        }}/>
                        <InputField id={'barcodeField'} className={'mt-3'} onChange={(e) => {
                            setBarcode(e.target.value);
                        }} placeholder={'Stregkode'}/>
                        <Button className={'mt-3 d-block w-full'} onClick={() => {
                            setCurrentStep(2);
                        }}>Indberet</Button>
                    </>
                )}
                {currentStep === 2 && (
                    <>
                        <div className="text-center mb-3">
                            <h1 className={'text-xl font-bold'}>Kasseindberetning<br/>Levering</h1>
                            <p className={'text-gray-500 text-lg'}>
                                Indtast antal kasser
                                <br/>
                                <strong>
                                    Følgeseddel: {barcode}
                                </strong>
                            </p>
                        </div>
                        <InputField className={'mt-3 text-lg'} type={'number'} placeholder={'Antal kasser leveret'}
                                    onChange={(e) => {
                                        setCounts({
                                            ...counts,
                                            deliveryBoxCount: e.target.value
                                        });
                                    }}/>
                        <InputField className={'mt-3 text-lg'} type={'number'} placeholder={'Antal paller leveret'}
                                    onChange={(e) => {
                                        setCounts({
                                            ...counts,
                                            deliveryPalletCount: e.target.value
                                        });
                                    }}/>
                        <Button className={'mt-3 d-block w-full'} onClick={() => {
                            setCurrentStep(3);
                        }}>Fortsæt</Button>
                    </>
                )}
                {currentStep === 3 && (
                    <>
                        <div className="text-center mb-3">
                            <h1 className={'text-xl font-bold'}>Kasseindberetning<br/>Retur</h1>
                            <p className={'text-gray-500 text-lg'}>
                                Indtast antal kasser
                                <br/>
                                <strong>
                                    Følgeseddel: {barcode}
                                </strong>
                            </p>
                        </div>
                        <InputField className={'mt-3 text-lg'} type={'number'} placeholder={'Antal kasser returneret'}
                                    onChange={(e) => {
                                        setCounts({
                                            ...counts,
                                            returnBoxCount: e.target.value
                                        });
                                    }}/>
                        <InputField className={'mt-3 text-lg'} type={'number'} placeholder={'Antal paller returneret'}
                                    onChange={(e) => {
                                        setCounts({
                                            ...counts,
                                            returnPalletCount: e.target.value
                                        });
                                    }}/>
                        <Button className={'mt-3 d-block w-full'} onClick={() => {
                            BoxReportService.createBoxReport(barcode, counts.deliveryBoxCount, counts.deliveryPalletCount, counts.returnBoxCount, counts.returnPalletCount);
                            setCurrentStep(1);
                        }}>Indberet</Button>
                    </>
                )}
            </Container>
        </>
    )
}