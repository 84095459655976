import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Users from "./views/Admin/Users";
import BoxReport from "./views/BoxReport";
import PendingBoxReports from "./views/PendingBoxReports";

const AppRoutes = [
    {
        index: true,
        element: <Login/>
    },
    {
        path: "/dashboard",
        element: <ProtectedRoute><Dashboard/></ProtectedRoute>,
    },
    {
        path: '/box-report',
        element: <ProtectedRoute><BoxReport/></ProtectedRoute>
    },
    {
        path: "/pending-box-reports",
        element: <ProtectedRoute><PendingBoxReports/></ProtectedRoute>
    },
    {
        path: '/admin/users',
        element: <ProtectedRoute><Users/></ProtectedRoute>
    },
];

export default AppRoutes;
